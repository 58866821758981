.ToolTip{
	&__Container{
		display: flex;
		align-items: center;
		justify-content: center;
		height: fit-content;
		width: 100%;
		position: absolute;
		background-color: rgba(67, 66, 66, 0.726);
		border-radius: 8px;
		padding: 5px;
	}

	&__Button{
		position: absolute;
		font-size: 15px;
		width: 22px;
		height: 22px;
		top: -15px;
		right: -15px;
		border-radius: 50%;
		background-color: rgba(42, 41, 41, 0.726);
		border: none;
		color: #ffffff;
		cursor: pointer;
	}

	&__Text{
		color: #ffffff;
		font-size: 12px;
		margin: 0;
	}
}
