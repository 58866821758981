.ErrorPage {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 80%;
	max-width: 400px;

	&__button {
		margin-bottom: 1rem !important; } }
